import { Helmet } from "react-helmet";
import Layout from "../../components/layout";

export default function GPet() {
  return (
	<Layout>
	  <Helmet>
		<title>G. Pet | Nathan Pasko</title>
		<meta name="description" content="G. Pet is a 3D virtual pet for Mac, Windows, and Linux with graphics inspired by PlayStation and gameplay inspired by the v. pets of the 1990s." />
	  </Helmet>
	  <div className="project-page">
		<ul className="horizontal-scroll">
		  <li>
			<picture>
			  <img
				src="http://avknights.com/pic/cav/dulmo.png"
				alt=""
				height="1024"
				width="1030"
			  />
			</picture>
		  </li>
		  <li>
			<picture>
			  <img
				src="http://avknights.com/pic/cav/vrybe2.png"
				height="900"
				width="1029"
			  />
			</picture>
		  </li>
		  <li>
			<picture>
			  <img
				src="http://avknights.com/pic/cav/vrybe1.png"
				height="900"
				width="1030"
			  />
			</picture>
		  </li>
		  <li>
			<picture>
			  <img
				src="http://avknights.com/pic/cav/mulmasha.png"
				alt=""
				height="900"
				width="1030"
			  />
			</picture>
		  </li>
		</ul>
		<h1>G. Pet</h1>
		<p>
		  G. Pet is a virtual pet from a weird, retro world.
		</p>
		<p>
			<a href="https://avknights.com/g-pet-manual/" target="_blank">Read the Instruction Booklet</a>
		</p>
		<p>
			Inspired by Digimon, Tamagotchi, Gigapet, Pkmn Pikachu, and the other virtual pets of the 1990s, and rendered in a PlayStation-like style… it’s G. Pet! Become the newest member of Dr. G’s research team and try your hand at raising a mysterious creature.
		</p>
		<p>Care for your pet — Touch them, feed them, clean up after them, play cards, and discipline them for naughty behavior to keep them alive.</p>
		<p>Randomized pet generation — Grow a different pet from every egg.</p>
		<p>Fun with physics — Pets, food, poop, a boombox, and even eggshells to push around.</p>
		<iframe frameborder="0" src="https://itch.io/embed/1004972?border_width=0&amp;fg_color=000&amp;link_color=e0e0e0&amp;border_color=fff" width="206" height="165"><a href="https://avknights.itch.io/g-pet">G. Pet by A.V. Knights</a></iframe>
	  </div>
	</Layout>
  );
}
